import { Box } from '@mui/material';
import React from 'react';

interface SidebarBoxProps {
  children: React.ReactNode;
}

const SidebarBox = ({ children }: SidebarBoxProps) => {
  return (
    <Box
      pt={2}
      pb={2}
      display="inline-flex"
      flexDirection="column"
    >
      {children}
    </Box>
  );
};

export default SidebarBox;
