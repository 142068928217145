import { IconButton, IconButtonProps, Tooltip } from '@mui/material';

interface TooltipButtonProps {
  title: string;
  color: IconButtonProps['color'];
  onClick: () => void;
  icon: React.ReactNode;
  isDisabled?: boolean;
}
const TooltipButton = ({ title, color, onClick, icon, isDisabled = false }: TooltipButtonProps) => {
  return (
    <Tooltip
      title={title}
      placement="top"
    >
      <IconButton
        color={color}
        onClick={onClick}
        disabled={isDisabled}
      >
        {icon}
      </IconButton>
    </Tooltip>
  );
};

export default TooltipButton;
