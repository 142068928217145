import { createTheme } from '@mui/material/styles';
// TODO: check for colors and use theme
const theme = createTheme({
    typography: {
        fontFamily: 'Arial, sans-serif',
    },
    palette: {
        primary: {
            main: '#8c195f', // OST-Brombeer
            light: '#d72864', // OST-Himbeer
            contrastText: '#fff' // OST-Weiss
        },
        grey: {
            500: '#878786', // OST Grau
            300: '#c6c6c5', // OST Hellgrau
            100: '#f2f2f2'  // OST Hellgrau heller
        },
        text: {
            primary: '#191919' // OST Schwarz
        }
    },
});

export default theme;