import { useMemo } from 'react';
import { Snippet } from '../interfaces/snippet';
import searchObject from '../utils/search-object';

export default function useFilteredEntries(
  entries: Snippet[],
  selectedText: string,
  selectedTags: string[]
) {
  const filteredEntries: Snippet[] = useMemo(() => {
    const filteredData = entries
      .filter((entry) => {
        switch (selectedText) {
          case '':
            return true;
          default:
            return searchObject(entry, selectedText);
        }
      })
      .filter((entry) => {
        const entryTags = entry.tags;
        return selectedTags.every((tag) => entryTags.includes(tag));
      });
    return filteredData;
  }, [entries, selectedText, selectedTags]);
  return filteredEntries;
}
