import React, { createContext, useContext, useState } from 'react';

interface EditModeContextType {
  editMode: {
    isEnabled: boolean;
    cardId: string | null;
  };
  setEditMode: React.Dispatch<
    React.SetStateAction<{
      isEnabled: boolean;
      cardId: string | null;
    }>
  >;
}

const EditModeContext = createContext<EditModeContextType>({
  editMode: { isEnabled: false, cardId: null },
  setEditMode: () => {},
});

const EditModeProvider = ({ children }: { children: React.ReactNode }) => {
  const [editMode, setEditMode] = useState<EditModeContextType['editMode']>({
    isEnabled: false,
    cardId: null,
  });

  return (
    <EditModeContext.Provider value={{ editMode, setEditMode }}>
      {children}
    </EditModeContext.Provider>
  );
};

const useEditMode = () => {
  return useContext(EditModeContext);
};

export { EditModeProvider, useEditMode };
