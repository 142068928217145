import BakeryDiningRoundedIcon from '@mui/icons-material/BakeryDiningRounded';
import { Stack } from '@mui/material';

interface PlaceholderProps {
  message: string;
}

const Placeholder = ({ message }: PlaceholderProps) => {
  return (
    <Stack
      alignItems="center"
      textAlign="center"
      p={6}
    >
      <BakeryDiningRoundedIcon sx={{ width: '160px', height: '160px' }} />
      {message}
    </Stack>
  );
};

export default Placeholder;
