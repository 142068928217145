import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Snippet } from '../interfaces/snippet';
import { useMessage } from './use-message';

export default function useFileUpload() {
  const [fileName, setFileName] = useState<string | null>(null);
  const [data, setData] = useState<Snippet[]>([]);
  const { message, severity, setMessage, setSeverity } = useMessage(
    null,
    'info'
  );
  const { t } = useTranslation();
  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const file = e.target.files ? e.target.files[0] : null;
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const json = JSON.parse((e.target?.result as string) || '');
          setData(json);
        };
        reader.readAsText(file);
        setFileName(file.name);
        setMessage(t('info.import.success'));
        setSeverity('success');
      }
    } catch (error: unknown) {
      if (!(error instanceof Error)) {
        throw error;
      }
      setMessage(`${t('info.import.error')}: ${error.message}`);
      setSeverity('warning');
    }
    e.target.value = '';
  };

  return { data, message, severity, fileName, handleFileUpload };
}

export {};
