import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import {
  Alert,
  AlertProps,
  Box,
  Snackbar,
  Stack,
  TextField,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFileDownload } from '../../hooks/use-file-download';
import useFileUpload from '../../hooks/use-file-upload';
import { Snippet } from '../../interfaces/snippet';
import MultiSelectCloud from '../shared/MultiSelectCloud';
import IconButton from '../shared/buttons/IconButton';
import CustomTypography from '../shared/buttons/typography/CustomTypography';
import Header from './Header';
import SidebarBox from './SidebarBox';
import { useEditMode } from '../../contexts/edit-mode-context';

interface SidebarProps {
  entries: Snippet[];
  tags: string[];
  sendText: (text: string) => void;
  sendTags: (tags: string[]) => void;
  sendSnippets: (snippets: Snippet[]) => void;
}

const Sidebar = ({
  entries,
  tags,
  sendText,
  sendTags,
  sendSnippets,
}: SidebarProps) => {
  const { t } = useTranslation();

  const [selectedText, setSelectedText] = useState<string>('');
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [message, setMessage] = useState<string | null>(null);
  const [messageSeverity, setMessageSeverity] =
    useState<AlertProps['severity']>('info');
  const [showSnackbar, setShowSnackbar] = useState(false);

  const fileUpload = useFileUpload();
  const fileDownload = useFileDownload(entries);

  const { editMode } = useEditMode();

  const editModeStyle = editMode.isEnabled ? { pointerEvents: 'none', opacity: 0.5 } : {};

  useEffect(() => {
    if (fileUpload.message || fileDownload.message) {
      setMessage(fileUpload.message || fileDownload.message);
      setMessageSeverity(fileUpload.severity || fileDownload.severity);
      setShowSnackbar(true);
    }
  }, [fileUpload, fileDownload]);

  useEffect(() => {
    sendTags(selectedTags);
    sendText(selectedText);
  }, [selectedText, selectedTags]);

  useEffect(() => {
    sendSnippets(fileUpload.data);
  }, [fileUpload.data]);

  const fileInput = useRef<HTMLInputElement>(null);

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedText(e.target.value);
  };

  const updateSelectedOptions = (options: string[]) => {
    setSelectedTags(options);
  };

  const handleImportClick = () => {
    fileInput.current!.click();
  };

  const closeSnackbar = () => {
    setShowSnackbar(false);
  };

  return (
    <Box
      p={2}
      sx={{
        position: 'sticky',
        top: 0,
        right: 0,
        minHeight: '100vh',
        ...editModeStyle,
      }}
    >
      <Stack>
        <Header />
        <SidebarBox>
          <CustomTypography
            text={t('appTitle')}
            headerLevel="h6"
            component="h1"
            sx={{ textAlign: 'center' }}
          />
        </SidebarBox>
        <SidebarBox>
          <Stack
            spacing={1}
            alignItems="center"
          >
            <IconButton
              icon={<FileUploadOutlinedIcon />}
              onClick={handleImportClick}
              variant="outlined"
              disabled={editMode.isEnabled}
            >
              {t('buttons.import')}
            </IconButton>
            <input
              type="file"
              style={{ display: 'none' }}
              ref={fileInput}
              onChange={fileUpload.handleFileUpload}
              accept=".json"
              disabled={editMode.isEnabled}
              tabIndex={editMode.isEnabled ? -1 : 0}
            />
            <IconButton
              icon={<SaveAltIcon />}
              onClick={fileDownload.handleFileDownload}
              variant="contained"
              color="success"
              disabled={entries.length === 0 || editMode.isEnabled}
            >
              {t('buttons.generate')}
            </IconButton>
          </Stack>
        </SidebarBox>
        <SidebarBox>
          <TextField
            id="text"
            variant="outlined"
            sx={{ height: '56px', marginBottom: '2rem' }}
            fullWidth
            value={selectedText}
            onChange={handleTextChange}
            label={t('home.textSearch')}
            disabled={editMode.isEnabled}
            tabIndex={editMode.isEnabled ? -1 : 0}
          />
          <MultiSelectCloud
            type="filter"
            options={tags}
            sendSelectedOptions={updateSelectedOptions}
          />
        </SidebarBox>
      </Stack>
      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={closeSnackbar}
      >
        <Alert
          severity={messageSeverity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Sidebar;
