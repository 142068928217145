import { useMemo } from 'react';
import { Snippet } from '../interfaces/snippet';

export default function useTagsAndOptions(entries: Snippet[]) {
  const tags = useMemo(() => {
    const tagFrequencyMap = entries.reduce((map, entry) => {
      entry.tags.forEach((tag) => {
        map.set(tag, (map.get(tag) || 0) + 1);
      });
      return map;
    }, new Map<string, number>());

    // ordered by frequency in descending order (current default)
    const sortedTags = Array.from(tagFrequencyMap.keys()).sort(
      (tagA, tagB) => tagFrequencyMap.get(tagB)! - tagFrequencyMap.get(tagA)!
    );

    return sortedTags;
  }, [entries]);

  return tags;
}
