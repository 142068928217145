import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useEditMode } from '../../contexts/edit-mode-context';

interface Language {
  nativeName: string;
  abbreviation: string;
}

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const lngs: Record<string, Language> = {
    de: { nativeName: 'Deutsch', abbreviation: 'DE' },
    en: { nativeName: 'English', abbreviation: 'EN' },
  };
  const {editMode} = useEditMode();

  return (
    <Box>
      {Object.keys(lngs).map((lng) => (
        <Button
          color="inherit"
          key={lng}
          sx={{
            fontWeight: i18n.language === lng ? 'bold' : 'normal',
          }}
          onClick={() => i18n.changeLanguage(lng)}
          disabled={i18n.resolvedLanguage === lng || editMode.isEnabled}
        >
          {lngs[lng].abbreviation}
        </Button>
      ))}
    </Box>
  );
};

export default LanguageSwitcher;
