interface CodeBlockProps {
  code: string;
}

const CodeBlock = ({ code }: CodeBlockProps) => {
  return (
    <pre style={{ textAlign: 'left' }}>
      <code style={{ whiteSpace: 'pre-wrap' }}>{code}</code>
    </pre>
  );
};

export default CodeBlock;
