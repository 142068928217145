import { useTranslation } from 'react-i18next';
import { Snippet } from '../../interfaces/snippet';
import MultiSelectCloud from '../shared/MultiSelectCloud';

interface CardTagCloudProps {
  isEditable: boolean;
  tags: string[];
  newlyAddedTags: string[];
  tempSelectedTags: string[];
  setTempSelectedTags: (tags: string[]) => void;
  handleAddNewTag: (entryId: string, newTag: string) => void;
  snippet: Snippet;
}

const CardTagCloud = ({
  isEditable,
  tags,
  newlyAddedTags,
  tempSelectedTags,
  setTempSelectedTags,
  handleAddNewTag,
  snippet,
}: CardTagCloudProps) => {
  const { t } = useTranslation();

  if (isEditable) {
    return (
      <MultiSelectCloud
        type="edit"
        options={[...tags, ...newlyAddedTags]}
        preSelectedOptions={tempSelectedTags}
        sendSelectedOptions={(selectedOptions) => setTempSelectedTags(selectedOptions)}
        onAddNewTag={(newTag) => handleAddNewTag(snippet.id, newTag)}
      />
    );
  } else if (snippet.tags && snippet.tags.length > 0) {
    return (
      <MultiSelectCloud
        type="show"
        options={snippet.tags}
        preSelectedOptions={snippet.tags}
      />
    );
  } else {
    return (
      <span style={{ lineHeight: '32px' }}>{t('placeholder.noTags')}</span>
    );
  }
};

export default CardTagCloud;
