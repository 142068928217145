import { Box, Grid, TextField } from '@mui/material';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useEditMode } from '../../contexts/edit-mode-context';
import { Snippet } from '../../interfaces/snippet';
import CodeBlock from '../shared/Codeblock';
import ClipboardAction from './ClipboardAction';

interface TextEditorProps {
  snippet: Snippet;
  isEditable: boolean;
  handleTextChange?: (
    id: string,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  copyTextToClipboard?: (content: string) => void;
}

const TextEditor = ({
  snippet,
  isEditable,
  handleTextChange,
  copyTextToClipboard,
}: TextEditorProps) => {
  const { t } = useTranslation();
  const { editMode } = useEditMode();

  if (isEditable && handleTextChange) {
    return (
      <Grid
        item
        md={11}
      >
        <Box sx={{ '& .MuiInputBase-input': { fontFamily: 'monospace' } }}>
          <TextField
            id="formText"
            label={t('label.text')}
            multiline
            minRows={3}
            maxRows={10}
            name="text"
            value={snippet.text}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleTextChange(snippet.id, e)
            }
            fullWidth
          />
        </Box>
      </Grid>
    );
  } else {
    return (
      <>
        <Grid
          item
          md={11}
        >
          <CodeBlock code={snippet.text} />
        </Grid>
        {copyTextToClipboard && (
          <ClipboardAction
            contentToCopy={snippet.text}
            onCopy={copyTextToClipboard}
            tooltipTitle={t('buttons.copyText')}
            disabled={editMode.isEnabled && editMode.cardId !== snippet.id}
          />
        )}
      </>
    );
  }
};
export default TextEditor;
