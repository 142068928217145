import DeleteIcon from '@mui/icons-material/Delete';
import {
  FormControl,
  Input as FormInput,
  FormLabel,
  Grid,
} from '@mui/material';
import { ChangeEvent } from 'react';
import { useEditMode } from '../../contexts/edit-mode-context';
import { Snippet } from '../../interfaces/snippet';
import TooltipButton from '../shared/buttons/TooltipButton';
import ClipboardAction from './ClipboardAction';

interface ImageEditorProps {
  isEditable: boolean;
  snippet: Snippet;
  dirtySnippet?: Snippet;
  handleImageChange: (
    snippetId: string,
    event: ChangeEvent<HTMLInputElement> | null
  ) => void;
  handleCopyImage?: (snippetId: string) => void;
  t: (key: string) => string;
}

const ImageEditor = ({
  isEditable,
  snippet,
  dirtySnippet,
  handleImageChange,
  handleCopyImage,
  t,
}: ImageEditorProps) => {
  const effectiveSnippet = dirtySnippet || snippet;
  const { editMode } = useEditMode();
  if (effectiveSnippet.image) {
    return (
      <>
        <Grid
          item
          md={11}
        >
          <img
            src={effectiveSnippet.image}
            alt={t('altText.uploadedImage')}
            style={{ maxWidth: '100%' }}
          />
        </Grid>
        <Grid
          item
          md={1}
        >
          {isEditable ? (
            <TooltipButton
              title={t('buttons.deleteImage')}
              color="error"
              onClick={() => handleImageChange(effectiveSnippet.id, null)}
              icon={<DeleteIcon />}
            />
          ) : (
            handleCopyImage && (
              <ClipboardAction
                contentToCopy={effectiveSnippet.image}
                onCopy={handleCopyImage}
                tooltipTitle={t('buttons.copyImage')}
                disabled={editMode.isEnabled && editMode.cardId !== snippet.id}
              />
            )
          )}
        </Grid>
      </>
    );
  } else if (isEditable) {
    return (
      <Grid
        item
        md={11}
      >
        <FormControl
          fullWidth
          margin="normal"
        >
          <FormLabel htmlFor="formImage">{t('label.image')}</FormLabel>
          <FormInput
            type="file"
            name="image"
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleImageChange(snippet.id, e)
            }
          />
        </FormControl>
      </Grid>
    );
  }
  return null;
};
export default ImageEditor;
