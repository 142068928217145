import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
} from '@mui/material';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useEditMode } from '../../contexts/edit-mode-context';
import { Snippet } from '../../interfaces/snippet';
import CardTagCloud from './CardTagCloud';
import ImageEditor from './ImageEditor';
import TextEditor from './TextEditor';

interface CardComponentProps {
  entry: Snippet;
  dirtySnippet: Snippet | null;
  isEditable: boolean;
  allTags: string[];
  handleInputChange: (
    id: string,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  handleTagChange: (id: string, tags: string[]) => void;
  handleImageChange: (
    id: string,
    event: React.ChangeEvent<HTMLInputElement> | null
  ) => void;
  handleAbort: () => void;
  handleDelete: (id: string) => void;
  handleSubmit: () => void;
  sendDirtySnippet: (snippet: Snippet) => void;
  sendCopyResult?: (success: boolean) => void;
}

const CardComponent = ({
  entry,
  dirtySnippet,
  isEditable,
  allTags,
  handleInputChange,
  handleTagChange,
  handleImageChange,
  handleAbort,
  handleDelete,
  handleSubmit,
  sendDirtySnippet,
  sendCopyResult,
}: CardComponentProps) => {
  const { editMode } = useEditMode();

  const [tempSelectedTags, setTempSelectedTags] = useState<string[]>(
    dirtySnippet && dirtySnippet.id === editMode.cardId
      ? dirtySnippet.tags
      : entry.tags
  );
  const [newlyAddedTags, setNewlyAddedTags] = useState<string[]>([]);

  useEffect(() => {
    if (isEditable && editMode.cardId === entry.id) {
      handleTagChange(entry.id, tempSelectedTags);
    }
  }, [tempSelectedTags]);

  useEffect(() => {
    // set newlyAddedTags & tempSelectedTags correctly when switching between cards & UNDO mode
    if (!isEditable && dirtySnippet?.id !== entry.id) {
      setNewlyAddedTags([]);
    } else if (!isEditable && dirtySnippet?.id === entry.id) {
      setTempSelectedTags(entry.tags);
    } else if (isEditable && dirtySnippet?.id === entry.id) {
      setTempSelectedTags(dirtySnippet.tags);
    }
  }, [isEditable]);

  const handleAddNewTag = (newTag: string) => {
    if (!allTags.includes(newTag)) {
      setNewlyAddedTags((prevTags) => [...prevTags, newTag]);
      setTempSelectedTags([...tempSelectedTags, newTag]);
    }
  };

  const handleEditClick = () => {
    setNewlyAddedTags([]);
    setTempSelectedTags(entry.tags);
    sendDirtySnippet(entry);
  };

  const copyTextToClipboard = async (text: string) => {
    if (sendCopyResult) {
      try {
        await navigator.clipboard.writeText(text);
        sendCopyResult(true);
      } catch (err) {
        sendCopyResult(false);
      }
    }
  };

  const copyImageToClipboard = async (url: string) => {
    if (sendCopyResult) {
      try {
        const response = await fetch(url);
        const blob = await response.blob();
        const data = [new ClipboardItem({ 'image/png': blob })];
        await navigator.clipboard.write(data);
        sendCopyResult(true);
      } catch (err) {
        sendCopyResult(false);
      }
    }
  };

  return (
    <Grid
      container
      sx={isEditable ? { zIndex: 5, position: 'relative' } : {}}
    >
      <Grid
        item
        xs={12}
      >
        <Card id={entry.id}>
          <CardHeader
            subheader={
              <Grid
                container
                gap={1}
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  alignItems: 'flex-start',
                  marginTop: '1rem',
                }}
              >
                <Grid
                  item
                  xs={1}
                  sx={{ lineHeight: '2rem' }}
                >
                  {t('label.tags')}:
                </Grid>{' '}
                <Grid
                  item
                  xs={10}
                >
                  <CardTagCloud
                    isEditable={isEditable}
                    tags={allTags}
                    newlyAddedTags={newlyAddedTags}
                    tempSelectedTags={tempSelectedTags}
                    setTempSelectedTags={(tagsToSet) =>
                      setTempSelectedTags(tagsToSet)
                    }
                    handleAddNewTag={(_, newTag) => handleAddNewTag(newTag)}
                    snippet={entry}
                  />
                </Grid>
              </Grid>
            }
          />
          <CardContent>
            {isEditable && dirtySnippet ? (
              <Grid
                container
                spacing={3}
                alignItems="flex-start"
              >
                <TextEditor
                  snippet={dirtySnippet}
                  isEditable={isEditable}
                  handleTextChange={handleInputChange}
                />
                <ImageEditor
                  isEditable={isEditable}
                  snippet={dirtySnippet}
                  handleImageChange={handleImageChange}
                  t={t}
                />
              </Grid>
            ) : (
              <Grid
                container
                spacing={3}
                alignItems="flex-start"
              >
                <TextEditor
                  snippet={entry}
                  isEditable={isEditable}
                  copyTextToClipboard={copyTextToClipboard}
                />
                <ImageEditor
                  isEditable={isEditable}
                  snippet={entry}
                  handleImageChange={handleImageChange}
                  handleCopyImage={copyImageToClipboard}
                  t={t}
                />
              </Grid>
            )}
          </CardContent>
          <CardActions>
            {isEditable ? (
              <>
                <Button onClick={handleSubmit}>Save</Button>
                <Button onClick={handleAbort}>Abort</Button>
                <Button onClick={() => handleDelete(entry.id)}>Delete</Button>
              </>
            ) : (
              <Button
                onClick={handleEditClick}
                disabled={editMode.isEnabled && editMode.cardId !== entry.id}
              >
                Edit
              </Button>
            )}
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  );
};
export default CardComponent;
