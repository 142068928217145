import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface NewEntryModalProps {
  showModal: boolean;
  handleCloseModal: () => void;
  children: React.ReactNode;
}

const NewEntryModal = ({
  showModal,
  handleCloseModal,
  children,
}: NewEntryModalProps) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={showModal}
      onClose={handleCloseModal}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>{t('title.addEntry')}</DialogTitle>
      <DialogContent>
        {children}
      </DialogContent>
    </Dialog>
  );
};

export default NewEntryModal;
