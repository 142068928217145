import { useEffect, useState } from 'react';

export const useMessage = (
  initialMessage: string | null,
  initialSeverity: 'error' | 'warning' | 'info' | 'success'
) => {
  const [message, setMessage] = useState<string | null>(initialMessage);
  const [severity, setSeverity] = useState<
    'error' | 'warning' | 'info' | 'success'
  >(initialSeverity);

  useEffect(() => {
    if (message && severity) {
      const timer = setTimeout(() => {
        setMessage(null);
        setSeverity('info');
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [message, severity]);

  return { message, severity, setMessage, setSeverity };
};
