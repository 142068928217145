// dependency-cruiser -T dot -x src/App.tsx | dot -T svg > dependency.svg
import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import Sidebar from './components/sidebar/Sidebar';
import SnippetCollection from './components/snippetcollection/SnippetCollection';
import { EditModeProvider, useEditMode } from './contexts/edit-mode-context';
import useFilteredEntries from './hooks/use-filtered-entries';
import useTagsAndOptions from './hooks/use-tags-and-options';
import { Snippet } from './interfaces/snippet';

function App() {
  const [selectedText, setSelectedText] = useState<string>('');
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [snippets, setSnippets] = useState<Snippet[]>([]);
  const [tags, setTags] = useState<string[]>(useTagsAndOptions(snippets));
  const [filteredSnippets, setFilteredSnippets] = useState<Snippet[]>([]);

  const { editMode } = useEditMode();

  const optionsFromHook = useTagsAndOptions(snippets);

  useEffect(() => {
    setTags(optionsFromHook);
  }, [snippets]);

  const filteredFromHook = useFilteredEntries(
    snippets,
    selectedText,
    selectedTags
  ); // TODO rename

  useEffect(() => {
    setFilteredSnippets(filteredFromHook);
  }, [filteredFromHook]);

  // callback functions for Sidebar
  const handleSearchTags = (tags: string[]) => {
    setSelectedTags(tags);
  };

  const handleSearchText = (text: string) => {
    setSelectedText(text);
  };

  const handleSnippets = (sentSnippets: Snippet[]) => {
    setSnippets(sentSnippets);
  };

  // callback functions for SnippetCollection
  const handleDeleteSnippet = (id: string) => {
    const updatedSnippets = snippets.filter((snippet) => snippet.id !== id);
    setSnippets(updatedSnippets);
  };

  const handleAddOrUpdateSnippet = (updatedSnippet: Snippet) => {
    const isExistingSnippet = snippets.find(
      (snippet) => snippet.id === updatedSnippet.id
    );
    let updatedSnippets;
    if (!isExistingSnippet) {
      updatedSnippets = [...snippets, updatedSnippet];
    } else {
      updatedSnippets = snippets.map((snippet) => {
        if (snippet.id === updatedSnippet.id) {
          return updatedSnippet;
        } else {
          return snippet;
        }
      });
    }
    setSnippets(updatedSnippets);
  };

  return (
    <EditModeProvider>
      <main>
        <Grid
          container
          direction="row"
        >
          <Grid
            item
            xs={4}
          >
            <Sidebar
              entries={snippets}
              tags={tags}
              sendTags={handleSearchTags}
              sendText={handleSearchText}
              sendSnippets={handleSnippets}
            />
          </Grid>
          <Grid
            item
            xs={8}
            pt={4}
            pb={6}
          >
            <SnippetCollection
              overallSnippetCount={snippets.length}
              filteredSnippets={filteredSnippets}
              tags={tags}
              selectedTags={selectedTags}
              sendDeleteRequest={handleDeleteSnippet}
              sendUpdateRequest={handleAddOrUpdateSnippet}
            />
          </Grid>
        </Grid>
      </main>
    </EditModeProvider>
  );
}

export default App;
