import CheckIcon from '@mui/icons-material/Check';
import {
  Box,
  Chip,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useEditMode } from '../../contexts/edit-mode-context';

interface MultiSelectCloudProps {
  options: string[];
  preSelectedOptions?: string[];
  type: 'filter' | 'edit' | 'show';
  sendSelectedOptions?: (selectedOptions: string[]) => void;
  onAddNewTag?: (newTag: string) => void;
}

const MultiSelectCloud = ({
  options,
  preSelectedOptions = [],
  type,
  sendSelectedOptions,
  onAddNewTag,
}: MultiSelectCloudProps) => {
  const { t } = useTranslation();
  const [selectedOptions, setSelectedOptions] =
    useState<string[]>(preSelectedOptions);
  const [isAddingTag, setIsAddingTag] = useState<boolean>(false);
  const [newTag, setNewTag] = useState<string>('');
  const { editMode } = useEditMode();

  useEffect(() => {
    if (type !== 'filter') {
      if (
        JSON.stringify(preSelectedOptions) !== JSON.stringify(selectedOptions)
      ) {
        setSelectedOptions(preSelectedOptions);
      }
    }
  }, [preSelectedOptions]);

  useEffect(() => {
    sendSelectedOptions && sendSelectedOptions(selectedOptions);
  }, [selectedOptions]);

  const handleToggle = (selectedOption: string) => {
    if (type === 'show') {
      return;
    }
    if (selectedOption === 'alle') {
      setSelectedOptions([]);
    } else {
      const newSelectedOptions = selectedOptions.includes(selectedOption)
        ? selectedOptions.filter((option) => option !== selectedOption)
        : [...selectedOptions, selectedOption];
      setSelectedOptions(newSelectedOptions);
    }
  };

  const handleAddTagClick = () => {
    setIsAddingTag(true);
  };

  const handleNewTagChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewTag(event.target.value);
  };

  const handleNewTagSubmit = () => {
    if (type !== 'show') {
      onAddNewTag && onAddNewTag(newTag);
      setSelectedOptions([...selectedOptions, newTag]);
      setIsAddingTag(false);
      setNewTag('');
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: '0.5rem',
        justifyContent: type === 'filter' ? 'center' : 'flex-start',
      }}
    >
      {type === 'filter' && (
        <Chip
          clickable={!editMode.isEnabled}
          label="alle"
          color={selectedOptions.length === 0 ? 'primary' : 'default'}
          onClick={() => handleToggle('alle')}
        />
      )}
      {type === 'edit' &&
        (isAddingTag ? (
          <TextField
            sx={{
              width: '8rem',
              height: '2rem',
              boxSizing: 'content-box',
              lineHeight: '1rem',
              '.MuiInputBase-root': {
                borderRadius: '1rem',
              },
              '& .MuiInputBase-input': {
                boxSizing: 'border-box',
                height: '2rem',
              },
            }}
            size="small"
            autoFocus
            value={newTag}
            onChange={handleNewTagChange}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleNewTagSubmit();
              }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  sx={{ height: '1rem', width: '1rem' }}
                >
                  <IconButton
                    onClick={handleNewTagSubmit}
                    sx={{ height: '1rem', width: '1rem' }}
                  >
                    <CheckIcon sx={{ height: '1rem', width: '1rem' }} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            variant="outlined"
          />
        ) : (
          <Chip
            sx={{ width: '8rem' }}
            label={t('buttons.addTag')}
            color="default"
            onClick={() => handleAddTagClick()}
          />
        ))}
      {options.map((option, i) => {
        const isClickable =
          (type === 'filter' && !editMode.isEnabled) ||
          (editMode.isEnabled && type === 'edit');
        const chipProps = isClickable
          ? { clickable: true, onClick: () => handleToggle(option) }
          : {};
        return (
          <Chip
            key={i}
            {...chipProps}
            label={option}
            color={selectedOptions.includes(option) ? 'primary' : 'default'}
          />
        );
      })}
    </Box>
  );
};

export default MultiSelectCloud;
