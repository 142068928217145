import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import logoDE from '../../public/logo-de.svg';
import logoEN from '../../public/logo-en.svg';
import LanguageSwitcher from './LanguageSwitcher';
import Logo from './Logo';

const Header = () => {
  const { i18n } = useTranslation();
  const logo = i18n.language === 'de' ? logoDE : logoEN;

  return (
    <Box
      display="flex"
      flexWrap="wrap"
      justifyContent="space-evenly"
      alignItems="center"
    >
      <Logo logo={logo} />
      <LanguageSwitcher />
    </Box>
  );
};

export default Header;
