import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Grid } from '@mui/material';
import TooltipButton from '../shared/buttons/TooltipButton';

interface ClipboardActionProps {
  contentToCopy: string;
  onCopy: (content: string) => void;
  tooltipTitle: string;
  disabled: boolean;
}

const ClipboardAction = ({
  contentToCopy,
  onCopy,
  tooltipTitle,
  disabled,
}: ClipboardActionProps) => {
  return (
    <Grid
      item
      md={1}
    >
      <TooltipButton
        title={tooltipTitle}
        color="default"
        onClick={() => onCopy(contentToCopy)}
        icon={
          <ContentCopyIcon
            width="1rem"
            height="1rem"
          />
        }
        isDisabled={disabled}
      />
    </Grid>
  );
};
export default ClipboardAction;
