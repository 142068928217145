import styled from '@emotion/styled';
import { Button } from '@mui/material';

export interface StyledButtonProps {
  onClick: () => void;
  variant?: 'text' | 'outlined' | 'contained';
  color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error';
  disabled?: boolean;
  children: React.ReactNode;
  sx?: any;
}

const ButtonWithStyles = styled(Button)({
  borderRadius: '2rem',
  width: 'fit-content',
  paddingLeft: '2rem',
  paddingRight: '2rem',
  '&.MuiButton-colorPrimary': {
    '&:hover': {
      backgroundColor: '#d72864',
      borderColor: '#d72864',
      color: 'white',
    },
  },
});

const StyledButton = ({
  onClick,
  variant,
  color,
  disabled,
  children,
  sx,
}: StyledButtonProps) => {
  return (
    <ButtonWithStyles
      onClick={onClick}
      variant={variant}
      color={color}
      disabled={disabled}
      sx={sx}
    >
      {children}
    </ButtonWithStyles>
  );
};

export default StyledButton;
