import { IconButton } from '@mui/material';

interface LogoProps {
  logo: string;
}

const Logo = ({ logo }: LogoProps) => {
  return (
    <IconButton
      edge="end"
      color="inherit"
      aria-label="logo"
      disabled
    >
      <img
        src={logo}
        alt="logo"
        height="50px"
      />
    </IconButton>
  );
};

export default Logo;
