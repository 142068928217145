export default function searchObject(
  obj: { [x: string]: any },
  searchText: string | RegExp
) {
  const regex = new RegExp(searchText, 'i'); // 'i' flag for case-insensitive matching

  for (let key in obj) {
    if (typeof obj[key] === 'string' && regex.test(obj[key])) {
      return true;
    } else if (Array.isArray(obj[key])) {
      for (let i = 0; i < obj[key].length; i++) {
        let item = obj[key][i];
        if (typeof item === 'string' && regex.test(item)) {
          return true;
        } else if (typeof item === 'object') {
          if (searchObject(item, searchText)) {
            return true;
          }
        }
      }
    } else if (typeof obj[key] === 'object') {
      if (searchObject(obj[key], searchText)) {
        return true;
      }
    }
  }

  return false;
}
