import { Typography, TypographyProps } from '@mui/material';
import React, { ElementType } from 'react';
import './typography.css';

interface CustomTypographyProps {
  text: string;
  headerLevel: TypographyProps['variant'];
  component?: TypographyProps['component'];
  htmlFor?: string;
  children?: React.ReactNode;
  sx?: TypographyProps['sx'];
}

const CustomTypography = ({
  text,
  headerLevel,
  component = headerLevel as ElementType<any, keyof JSX.IntrinsicElements>,
  htmlFor,
  children,
  sx,
}: CustomTypographyProps) => {
  return (
    <Typography
      variant={headerLevel}
      component={component as ElementType<any, keyof JSX.IntrinsicElements>}
      htmlFor={htmlFor}
      sx={sx}
    >
      {text}
      {children}
    </Typography>
  );
};

export default CustomTypography;
