import AddCircleIcon from '@mui/icons-material/AddCircle';
import {
  Alert,
  AlertProps,
  Box,
  Button,
  Container,
  Snackbar,
  Stack,
  Typography,
} from '@mui/material';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { useEditMode } from '../../contexts/edit-mode-context';
import { Snippet } from '../../interfaces/snippet';
import IconButton from '../shared/buttons/IconButton';
import CardComponent from './Card';
import NewEntryModal from './NewEntryModal';
import Placeholder from './Placeholder';

interface SnippetCollectionProps {
  overallSnippetCount: number;
  filteredSnippets: Snippet[];
  tags: string[];
  selectedTags: string[];
  sendDeleteRequest: (id: string) => void;
  sendUpdateRequest: (snippet: Snippet) => void;
}

const SnippetCollection = ({
  overallSnippetCount,
  filteredSnippets,
  tags,
  selectedTags,
  sendDeleteRequest,
  sendUpdateRequest,
}: SnippetCollectionProps) => {
  const { t } = useTranslation();

  const createEmptySnippet = (): Snippet => ({
    id: uuidv4(),
    text: '',
    tags: [],
    image: '',
  });

  const [showModal, setShowModal] = useState(false);
  const [dirtySnippet, setDirtySnippet] = useState<Snippet | null>(null);
  const [editAborted, setEditAborted] = useState<boolean>(false);

  const [message, setMessage] = useState<string | null>(null);
  const [messageSeverity, setMessageSeverity] =
    useState<AlertProps['severity']>('info');
  const [showSnackbar, setShowSnackbar] = useState(false);

  const { editMode, setEditMode } = useEditMode();

  const newSnippetRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (dirtySnippet) {
      dirtySnippet.tags = selectedTags;
    }
  }, [selectedTags]);

  const handleAddNewSnippetClick = () => {
    if (showSnackbar) {
      handleSnackbarClose();
    }
    const emptySnippet = createEmptySnippet();
    emptySnippet.tags = selectedTags;
    setDirtySnippet(emptySnippet);
    setEditMode({ isEnabled: true, cardId: emptySnippet.id });
    // showNewSnippet();
    // handleShowModal();
  };

  const showNewSnippet = () => {
    if (dirtySnippet) {
      // scroll the new snippet into view
      newSnippetRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  useEffect(() => {
    if (
      dirtySnippet &&
      !filteredSnippets.find((x) => x.id === dirtySnippet.id) &&
      editMode.isEnabled &&
      editMode.cardId === dirtySnippet.id
    ) {
      showNewSnippet();
    }
  }, [dirtySnippet]);

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleAddClick = () => {
    handleCloseModal();
    const snippetToSend = dirtySnippet ? dirtySnippet : createEmptySnippet();
    setEditMode({ isEnabled: false, cardId: null });
    if (snippetToSend !== null) {
      if (
        snippetToSend.text !== '' ||
        snippetToSend.tags.length > 0 ||
        snippetToSend.image !== ''
      ) {
        setDirtySnippet(null);
        sendUpdateRequest(snippetToSend);
        setMessage('successfully added');
        setMessageSeverity('success');
        setShowSnackbar(true);
      }
      // TODO: handle empty request case with some kind of feedback
    }
  };

  const handleClearClick = () => {
    handleCloseModal();
  };

  const findSnippet = (id: string) => {
    return filteredSnippets.find((snippet) => snippet.id === id);
  };

  const findAndUpdateDirtySnippet = (
    id: string,
    updatedFields: Partial<Snippet>
  ) => {
    const toUpdateSnippet = findSnippet(id);
    if (toUpdateSnippet) {
      setDirtySnippet({ ...toUpdateSnippet, ...updatedFields });
    }
  };

  const handleInputChange = (
    id: string,
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    // TODO: check if this null handling is good
    if (id === dirtySnippet?.id) {
      setDirtySnippet({ ...dirtySnippet, [e.target.name]: e.target.value });
    } else {
      findAndUpdateDirtySnippet(id, { [e.target.name]: e.target.value });
    }
  };

  const handleTagChange = (id: string, tags: string[]) => {
    if (id === dirtySnippet?.id) {
      setDirtySnippet({ ...dirtySnippet, tags: tags });
    } else {
      findAndUpdateDirtySnippet(id, { tags: tags });
    }
  };

  const readFileAsDataURL = (file: File): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as string);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });

  const handleImageUpdate = async (
    id: string,
    e: ChangeEvent<HTMLInputElement> | null
  ) => {
    let image = '';

    if (e) {
      const file = e.target.files ? e.target.files[0] : null;
      if (file) {
        image = await readFileAsDataURL(file);
      }
    }

    if (id === dirtySnippet?.id) {
      setDirtySnippet({ ...dirtySnippet, image });
    } else {
      findAndUpdateDirtySnippet(id, { image });
    }
  };

  const handleRemoveClick = (id: string) => {
    sendDeleteRequest(id);
  };

  const handleSubmit = () => {
    if (dirtySnippet) {
      setEditMode({ isEnabled: false, cardId: null });
      sendUpdateRequest(dirtySnippet);
      setDirtySnippet(null);
      setMessage('successfully updated');
      setMessageSeverity('success');
      setShowSnackbar(true);
    }
  };

  const handleAbort = () => {
    setEditAborted(true);
    setEditMode({ isEnabled: false, cardId: null });
    handleCloseModal();
    setMessage('edit aborted');
    setMessageSeverity('warning');
    setShowSnackbar(true);
  };

  const editOverlay = editMode.isEnabled ? (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        pointerEvents: 'auto',
        opacity: 0.5,
        zIndex: 2,
      }}
      onClick={handleAbort}
    />
  ) : null;

  const handleSnackbarClose = () => {
    if (showSnackbar) {
      setShowSnackbar(false);
      setEditAborted(false);
      setDirtySnippet(null);
    }
  };

  const scrollEditableSnippetIntoView = (id: string) => {
    const editableSnippet = document.getElementById(id);
    if (editableSnippet) {
      editableSnippet.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleNewDirtySnippet = (snippet: Snippet) => {
    if (showSnackbar) {
      handleSnackbarClose();
    }
    setEditAborted(false);
    setDirtySnippet(snippet);
    // handleTagChange(snippet.id, snippet.tags); causes a rerender and dirtysnippet is not set correctly when same id
    setEditMode({ isEnabled: true, cardId: snippet.id });
    scrollEditableSnippetIntoView(snippet.id);
  };

  const handleUndoClick = () => {
    if (
      dirtySnippet &&
      !filteredSnippets.some((snippet) => snippet.id === dirtySnippet.id)
    ) {
      setShowSnackbar(false);
      setEditAborted(false);
      setEditMode({ isEnabled: true, cardId: dirtySnippet.id });
      handleShowModal();
    } else if (
      dirtySnippet &&
      filteredSnippets.some((snippet) => snippet.id === dirtySnippet.id)
    ) {
      setShowSnackbar(false);
      setEditAborted(false);
      setEditMode({ isEnabled: true, cardId: dirtySnippet.id });
    }
    dirtySnippet && scrollEditableSnippetIntoView(dirtySnippet.id);
  };

  const showCopyResultSnackbar = (success: boolean) => {
    const resultMessage = success ? 'successfully copied' : 'error copying';
    const resultType = success ? 'success' : 'error';
    setMessage(resultMessage);
    setMessageSeverity(resultType);
    setShowSnackbar(true);
  };

  const renderSnippetTeaser = () => {
    return (
      <Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
      >
        <Typography
          variant="h3"
          sx={{ textAlign: 'center' }}
        >
          Noch keine Schnipsel
          {/* {t('placeholder.newSnippet')} */}
        </Typography>
        <Placeholder message={t('placeholder.noEntries')} />
        <IconButton
          icon={<AddCircleIcon />}
          onClick={handleAddNewSnippetClick}
          variant="contained"
          color="success"
          sx={{
            margin: '2rem auto 0',
            boxShadow: '0 3px 5px 3px rgba(0, 0, 0, 0.3)',
          }}
          disabled={editMode.isEnabled}
        >
          {t('buttons.add')}
        </IconButton>
      </Box>
    );
  };

  const renderNewSnippet = () => {
    return (
      dirtySnippet &&
      !filteredSnippets.find((x) => x.id === dirtySnippet.id) &&
      editMode.isEnabled &&
      editMode.cardId === dirtySnippet.id && (
        <div
          id={dirtySnippet.id}
          ref={newSnippetRef}
        >
          <CardComponent
            key={dirtySnippet.id}
            entry={dirtySnippet}
            dirtySnippet={dirtySnippet}
            isEditable={true}
            allTags={tags}
            handleInputChange={handleInputChange}
            handleTagChange={(_, newTags) =>
              handleTagChange(dirtySnippet.id, newTags)
            }
            handleImageChange={(_, e) => handleImageUpdate(dirtySnippet.id, e)}
            handleAbort={handleAbort}
            handleDelete={handleRemoveClick}
            handleSubmit={handleAddClick}
            sendDirtySnippet={(snippet) => handleNewDirtySnippet(snippet)}
          />
        </div>
      )
    );
  };

  const renderSnippets = () => {
    if (overallSnippetCount === 0) {
      return (
        <>
          {renderSnippetTeaser()}
          {renderNewSnippet()}
        </>
      );
    } else if (filteredSnippets.length === 0) {
      return (
        <>
          <Placeholder message={t('placeholder.noFilteredEntries')} />
          {renderNewSnippet()}
        </>
      );
    } else {
      return (
        <>
          {filteredSnippets.map((x, i) => (
            <CardComponent
              key={x.id}
              entry={x}
              dirtySnippet={dirtySnippet}
              isEditable={
                !editAborted &&
                dirtySnippet !== null &&
                dirtySnippet.id === x.id
              }
              allTags={tags}
              handleInputChange={handleInputChange}
              handleTagChange={(_, newTags) => handleTagChange(x.id, newTags)}
              handleImageChange={(_, e) => handleImageUpdate(x.id, e)}
              handleAbort={handleAbort}
              handleDelete={handleRemoveClick}
              handleSubmit={handleSubmit}
              sendDirtySnippet={(snippet) => handleNewDirtySnippet(snippet)}
              sendCopyResult={(success) => showCopyResultSnackbar(success)}
            />
          ))}
          {renderNewSnippet()}
        </>
      );
    }
  };

  const undoAction = (
    <Button
      color="secondary"
      size="small"
      onClick={handleUndoClick}
    >
      undo
    </Button>
  );

  return (
    <Container>
      {editOverlay}
      <Stack
        spacing={3}
        direction="column"
        position="relative"
      >
        {renderSnippets()}
      </Stack>
      <NewEntryModal
        showModal={showModal}
        handleCloseModal={handleClearClick}
      >
        {dirtySnippet !== null && (
          <CardComponent
            key={dirtySnippet.id}
            entry={dirtySnippet}
            dirtySnippet={dirtySnippet}
            isEditable={true}
            allTags={tags}
            handleInputChange={handleInputChange}
            handleTagChange={(_, newTags) =>
              handleTagChange(dirtySnippet.id, newTags)
            }
            handleImageChange={(_, e) => handleImageUpdate(dirtySnippet.id, e)}
            handleAbort={handleAbort}
            handleDelete={handleRemoveClick}
            handleSubmit={handleAddClick}
            sendDirtySnippet={(snippet) => handleNewDirtySnippet(snippet)}
          />
        )}
      </NewEntryModal>
      {overallSnippetCount !== 0 && (
        <IconButton
          icon={<AddCircleIcon />}
          onClick={handleAddNewSnippetClick}
          variant="contained"
          color="success"
          sx={{
            position: 'fixed',
            bottom: '20px',
            right: 'calc(100% - 66.67%)',
            transform: 'translateX(50%)',
            boxShadow: '0 3px 5px 3px rgba(0, 0, 0, 0.3)',
          }}
          disabled={editMode.isEnabled}
        >
          {t('buttons.add')}
        </IconButton>
      )}
      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          severity={messageSeverity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {message}
          {messageSeverity === 'warning' && undoAction}
        </Alert>
      </Snackbar>
    </Container>
  );
};
export default SnippetCollection;
