import React from 'react';
import StyledButton, { StyledButtonProps } from './StyledButton';

interface IconButtonProps extends StyledButtonProps {
  icon: React.ReactElement;
}

const IconButton = ({ icon, children, ...props }: IconButtonProps) => {
  return (
    <StyledButton {...props}>
      {React.cloneElement(icon, { sx: { marginRight: '0.5rem' } })}
      {children}
    </StyledButton>
  );
};

export default IconButton;
