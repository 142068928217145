import { useTranslation } from 'react-i18next';
import { Snippet } from '../interfaces/snippet';
import { useMessage } from './use-message';

// TODO: use localstorage to store snippets as well (snippet state in App.tsx)
export const useFileDownload = (snippets: Snippet[]) => {
  const { t } = useTranslation();

  const { message, severity, setMessage, setSeverity } = useMessage(
    null,
    'info'
  );

  const handleFileDownload = () => {
    try {
      const dataStr = JSON.stringify(snippets);
      const blob = new Blob([dataStr], { type: 'application/json' });
      const url = URL.createObjectURL(blob);
      const dateString = new Date().toISOString().replace(/:/g, '-');

      const downloadAnchorNode = document.createElement('a');
      downloadAnchorNode.setAttribute('href', url);
      downloadAnchorNode.setAttribute('download', `snippet-collection_${dateString}.json`);
      document.body.appendChild(downloadAnchorNode);
      downloadAnchorNode.click();
      downloadAnchorNode.remove();

      URL.revokeObjectURL(url);

      setMessage(t('info.export.success'));
      setSeverity('success');
    } catch (error: unknown) {
      if (!(error instanceof Error)) {
        throw error;
      }
      setMessage(`${t('info.export.error')}: ${error.message}`);
      setSeverity('error');
    }
  };

  return { handleFileDownload, message, severity };
};
